:root {
  --PRIMARY_COLOR: "#E8b504";
  --white: #faf0e6;
  --SECONDARY_1_COLOR: #ebe6e3;
  --SECONDARY_2_COLOR: #89a09b;
  --SECONDARY_3_COLOR: #c9bab1;
  --SECONDARY_4_COLOR: #246f6c;
  --SECONDARY_5_COLOR: #ededed;
}


.main_container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: whitesmoke;
}

.logo {
  width: 130px;
}

.side_bar_background {
  /*background: var(--PRIMARY_COLOR) !important;*/
  /*background: url("../assets/img/main_color_bg.png") !important;*/
  background: black;
}

.side_bar_drawer {
  width: 270px !important;
  background: black;
  /*background: url("../assets/img/main_color_bg.png") !important;*/
}

.ant-menu-submenu-selected {
  color: var(--white);
}

.ant-menu.custom {
  color: var(--white);
  background: black;
}

.custom .ant-menu-submenu-active {
  color: var(--white) !important;
  background: black;
}

.custom .ant-menu-submenu-active .ant-menu-submenu-title {
  color: var(--white) !important;
  background: black;
}
.custom .ant-menu-submenu-title:hover {
  background: black;
  color: var(--white);
}

.custom .ant-menu-sub.ant-menu-inline {
  background: black;
  color: var(--white);
}
.custom .ant-menu-submenu-arrow {
  color: var(--white);
  background: black;
}
.custom .ant-menu-submenu-arrow:hover {
  color: var(--white);
  background: black;
}
.custom .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: black;
  color: var(--white);
}
.custom .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover {
  background: black;
  color: var(--white);
}

.custom .ant-menu-submenu-selected {
  color: var(--white);
}

.custom .ant-menu-item-active {
  color: var(--white);
  background: black;
}
.custom .ant-menu-item {
  padding: 0;
  margin: 0;
}

.custom .ant-menu-item-active :hover {
  color: var(--white);
  background: black;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover,
.ant-menu-title-content,
.ant-menu-title-content a::before,
.ant-menu-root,
.ant-menu,
.ant-menu-inline,
.ant-menu-light,
.ant-menu-item-icon{
  background: none !important;
}
/*    custom text-white fw-bold font-size-17 pb-4*/
